/// <reference types="@types/googlemaps" />
import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators, UntypedFormBuilder } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api/api.service';
import { CinemasService } from 'src/app/providers/cinemas.service';

import { MapsAPILoader } from '@agm/core';
import { MatDialog } from '@angular/material/dialog';
import { SalaProiezioniComponent } from 'src/app/components/sala-proiezioni/sala-proiezioni.component';

@Component({
  selector: 'app-cinema-single',
  templateUrl: './cinema-single.component.html',
  styleUrls: ['./cinema-single.component.scss']
})

export class CinemaSingleComponent implements OnInit {

  @ViewChild('autoSearch') autoSearch: ElementRef<HTMLInputElement>;
@ViewChild('regioneInput') regioneInput: ElementRef<HTMLInputElement>;

  @ViewChild('autoRegione') matAutoRegioneComplete: MatAutocomplete;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  /**Controllo dell'input per la ricerca del posto */
  searchControl: UntypedFormControl;

  form: UntypedFormGroup;
  loading = true;

  title = '';
  id;

  lat:number = 0;
  lng:number = 0;
  titleCinema = '';
  icon = {
    url: "../../../assets/images/movie.png",
    scaledSize: {
      height : 30,
      width: 30
    }
  };

  indirizzo = '';
  comune = '';
  cap = '';
  provincia = '';

  regioni = [];
  filteredRegioni: Observable<string[]>;
  regioneCtrl = new UntypedFormControl();



  private _filter(all, value: string): string[] {
    const filterValue = value.toLowerCase();
    return all.filter(d => d.toLowerCase().indexOf(filterValue) >= 0);
  }

  constructor(private dialog: MatDialog, private route: ActivatedRoute, private router: Router, private fb: UntypedFormBuilder,
              private cinemas: CinemasService,private api: ApiService,
              private mapsAPILoader: MapsAPILoader, private ngZone: NgZone ) {
    // inizializzo le form
    this.form = this.cinemas.getForm();
    this.searchControl = new UntypedFormControl();
  }

  // Funzione per aprire il dialog
  openProiezioniDialog(sala: any): void {
    const proiezioni = sala.get('proiezioni').value;
    console.log('Proiezioni per sala:', proiezioni); // Verifica se proiezioni contiene dati

    this.dialog.open(SalaProiezioniComponent, {
      width: '90vw',
      data: {
        nomeSala: sala.get('nomeSala').value,
        proiezioni: proiezioni
      }
    });
  }


  ngOnInit() {
    this.cinemas.getRegione().subscribe((resultRegioni) => {
      this.regioni = resultRegioni.data;
      this.filteredRegioni = this.regioneCtrl.valueChanges.pipe(
        startWith(null),
        map((regione: string | null) => (regione && regione.length > 0) ?
          this._filter(resultRegioni.data, regione) : [])
      );

      this.route.params.subscribe((params) => {
        if (params && params.id && params.id === 'new') {
          // nuovo elemento
          this.form = this.cinemas.getForm();
          this.loading = false;
          this.id = params.id;
          this.title = 'Inserisci un nuovo cinema';
        } else {
          // modifica elemento esistente
          this.title = 'Modifica cinema';
          this.id = params.id;
          this.cinemas.get(params.id).subscribe((data) => {
            if (data && data.success && data.success === true) {
              // Usa direttamente data.data.saleCinemaData che contiene già le proiezioni
              this.form = this.cinemas.getForm(data.data);

              // Inizializza saleCinemaData se non è presente
              if (!this.form.get('saleCinemaData')) {
                this.form.setControl('saleCinemaData', this.fb.array([]));
              }

              const saleCinemaData = this.form.get('saleCinemaData') as UntypedFormArray;

              // Pulizia del FormArray per evitare duplicazioni
              while (saleCinemaData.length !== 0) {
                saleCinemaData.removeAt(0);
              }

              // Verifica se saleCinemaData esiste nei dati recuperati
              if (data.data.saleCinemaData && data.data.saleCinemaData.length > 0) {
                data.data.saleCinemaData.forEach(sala => {
                  // Aggiungi direttamente la sala con le sue proiezioni
                  saleCinemaData.push(this.fb.group({
                    nomeSala: [sala.nomeSala, Validators.required],
                    numeroSala: [sala.numeroSala, Validators.required],
                    capacita: [sala.capacita, Validators.required],
                    dotazioni: [sala.dotazioni || []],
                    screenId: [sala.screenId || 0],  // screenId

                    // Usa le proiezioni esistenti già fornite dall'API
                    proiezioni: this.fb.array(sala.proiezioni.map(proiezione => this.fb.group({
                      titoloFilm: [proiezione.titoloFilm, Validators.required],
                      dataOra: [proiezione.dataOra, Validators.required],
                      durata: [proiezione.durata, Validators.required]
                    })))
                  }));
                });
              }

              // Imposta latitudine e longitudine
              this.lat = parseFloat(this.form.value['lat']);
              this.lng = parseFloat(this.form.value['lng']);
              this.titleCinema = this.form.value['title'];
              this.loading = false;
            } else {
              this.router.navigate(['/cinemas']);
            }
          });

        }
      });
    });
  }

  getSale() {
    const urlSync = this.form.get('url_sync').value; // Ottieni l'URL di sincronizzazione dal form

    if (urlSync) {
      // Chiama l'API per ottenere le sale usando l'url_sync
      this.cinemas.getSchedulingFromApi(urlSync).subscribe({
        next: (response: any) => {
          const _response = response.data;
          const saleCinemaData = this.form.get('saleCinemaData') as UntypedFormArray;

          // Creo un oggetto per le sale basate su screenId
          const saleConProiezioni = {};

          _response.DS.Scheduling.Events.forEach((event) => {
            event.Days.forEach((day) => {
              day.Performances.forEach((performance) => {
                const screenId = performance.ScreenId; // Usa screenId come chiave
                const proiezione = {
                  titoloFilm: event.Title,
                  dataOra: performance.StartTime,
                  durata: performance.Duration
                };

                // Se lo screenId non esiste, inizializza l'array delle proiezioni
                if (!saleConProiezioni[screenId]) {
                  saleConProiezioni[screenId] = [];
                }

                // Aggiungi la proiezione a quella sala (screenId)
                saleConProiezioni[screenId].push(proiezione);
              });
            });
          });

          console.log(saleConProiezioni);  // Mostra il risultato delle sale con le proiezioni basate su screenId

          // Ora popola il form basato su screenId e le proiezioni
          Object.keys(saleConProiezioni).forEach((screenId) => {
            const salaEsistente = saleCinemaData.controls.find(sala => sala.get('screenId').value === parseInt(screenId, 10));

            if (!salaEsistente) {
              saleCinemaData.push(this.fb.group({
                nomeSala: [`Sala ${screenId}`, Validators.required],  // Usa lo screenId per il nome della sala
                numeroSala: [screenId, Validators.required],
                capacita: [100, Validators.required],  // Imposta la capacità di default a 100
                dotazioni: [[]],  // Inizializza come array vuoto
                screenId: [parseInt(screenId, 10)],  // Aggiungi l'ID dello schermo
                proiezioni: this.fb.array(saleConProiezioni[screenId].map(proiezione => this.fb.group({
                  titoloFilm: [proiezione.titoloFilm, Validators.required],
                  dataOra: [proiezione.dataOra, Validators.required],
                  durata: [proiezione.durata, Validators.required]
                })))
              }));
            } else {
              // Se la sala esiste già, aggiorna le proiezioni
              const proiezioniArray = salaEsistente.get('proiezioni') as UntypedFormArray;
              saleConProiezioni[screenId].forEach((proiezione) => {
                proiezioniArray.push(this.fb.group({
                  titoloFilm: [proiezione.titoloFilm, Validators.required],
                  dataOra: [proiezione.dataOra, Validators.required],
                  durata: [proiezione.durata, Validators.required]
                }));
              });
            }
          });
        },
        error: (err) => {
          console.error("Errore durante il caricamento delle sale: ", err);
        },
        complete: () => {
          console.log("Caricamento delle sale completato.");
        }
      });
    } else {
      console.error("URL di sincronizzazione non trovato.");
    }
  }



  placeChanged(event) {
    this.mapsAPILoader.load().then(() => {
      const input = this.autoSearch.nativeElement;
      const autocomplete = new google.maps.places.Autocomplete(input, {});
      autocomplete.setComponentRestrictions({ 'country': ['IT'] });

      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (!place.geometry) {
            console.warn('No geometry returned for place');
            return;
          }
          // Imposta latitudine e longitudine
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();
          this.form.controls['lat'].setValue(this.lat);
          this.form.controls['lng'].setValue(this.lng);
          this.getGeoLocation(this.lat, this.lng);
        });
      });
    });
  }


  placeMarker(event){
    this.lat = event.coords.lat;
    this.lng = event.coords.lng;
    this.form.controls['lat'].setValue(this.lat);
    this.form.controls['lng'].setValue(this.lng);
    this.getGeoLocation(this.lat,this.lng);
  }

  getGeoLocation(lat: number, lng: number) {
    if (navigator.geolocation) {
      const geocoder = new google.maps.Geocoder();
      const latlng = new google.maps.LatLng(lat, lng);
      const request = { location: latlng };

      geocoder.geocode(request, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          const result = results[0];
          const rsltAdrComponent = result.address_components;
          const resultLength = rsltAdrComponent.length;
          if (result != null && resultLength > 0) {
            const state =  rsltAdrComponent.filter(function(addr){
              return (addr.types[0]=='country') ? 1 : 0;
            });
            if( state[0].short_name == 'IT'){
              const address =  rsltAdrComponent.filter(function(addr){
                return (addr.types[0]=='route')?1:0;
              });
              const nr_address = rsltAdrComponent.filter(function(addr){
                return (addr.types[0]=='street_number')?1:0;
              });
              const add = (address.length > 0) ? address[0].long_name : "Nessun indirizzo segnalato";
              const nr_add = (nr_address.length > 0) ? nr_address[0].long_name : "Nessun numero segnalato";
              this.indirizzo = add + ', ' + nr_add;
              this.form.controls['indirizzo'].setValue(this.indirizzo);

              const cap =  rsltAdrComponent.filter(function(addr){
                return (addr.types[0]=='postal_code') ? 1 : 0;
              });
              (cap.length > 0) ? this.cap = cap[0].long_name : this.cap = "Nessun cap segnalato";
              this.form.controls['cap'].setValue(this.cap);

              const comune = rsltAdrComponent.filter(function(addr){
                return (addr.types[0]=='administrative_area_level_3')?1:0;
              });
              (comune.length > 0) ? this.comune = comune[0].long_name : this.comune = "Nessun comune segnalato";
              this.form.controls['comune'].setValue(this.comune);

              const provincia = rsltAdrComponent.filter(function(addr){
                return (addr.types[0]=='administrative_area_level_2')?1:0;
              });
              (provincia.length > 0) ? this.provincia = provincia[0].short_name : this.provincia = "Nessuna provincia segnalata";
              this.form.controls['provincia'].setValue(this.provincia);

              const regione = rsltAdrComponent.filter(function(addr){
                return (addr.types[0]=='administrative_area_level_1')?1:0;
              });
              (regione.length > 0) ?  this.form.controls['regione'].setValue(regione[0].long_name) : this.form.controls['regione'].setValue("Nessuna regione segnalata");

              console.log(this.indirizzo,this.cap,this.comune,this.provincia);
            } else {
              console.log("Non sei in Italia");
              alert("Non sei in Italia");
            }
          } else {
            console.log("No address available!");
          }
        }
      });
    }
  };

  annulla() {
    this.router.navigate(['/cinemas']);
  }

  submit() {
    if (this.form.valid) {
      this.loading = true;
      this.cinemas.save(this.form).subscribe((result) => {
        this.loading = false;
        if (result && result['success'] === true) {
          this.router.navigate(['/cinemas']);
        } else {
          alert('Errore durante il salvataggio');
        }
      });
    }
  }


  delete() {
    if (confirm('Si è sicuri di eliminare questa voce?')) {
      this.loading = true;
      this.cinemas.delete(this.id).subscribe((result) => {
        this.loading = false;
        if (result && result.success === true) {
          this.router.navigate(['/cinemas']);
        } else {
          alert('Errore durante l\'eliminazione');
        }
      });
    }
  }


  removeChip(field: string, item: string): void {
    const tmpData = this.form.value[field] || [];
    const index = tmpData.indexOf(item);

    if (index >= 0) {
      tmpData.splice(index, 1);
      this.form.controls[field].setValue(tmpData);
    }
  }

  selectedRegioneChip(event: MatAutocompleteSelectedEvent): void {
    const regione = event.option.viewValue;
    this.form.controls['regione'].setValue(regione);
    this.regioneInput.nativeElement.value = '';
    this.regioneCtrl.setValue(null);
  }

  addInputRegioneChip(event: MatChipInputEvent): void {
    if (!this.matAutoRegioneComplete.isOpen) {
      const value = event.value.trim();
      if (value) {
        this.form.controls['regione'].setValue(value);
      }
      if (event.input) {
        event.input.value = '';
      }
      this.regioneCtrl.setValue(null);
    }
  }

  addImageGalleryData() {
    this.cinemas.addImageGalleryData(this.form);
  }

  removeImageGalleryData(index) {
    if (confirm(`Si è sicuri di eliminare questa scheda?`)) {
      const imageGalleryData = this.form.get('imageGalleryData') as UntypedFormArray;
      imageGalleryData.removeAt(index);
    }
  }

  // Metodo per aggiungere una nuova sala
  addSala() {
    this.cinemas.addSala(this.form);  // Chiama il servizio per aggiungere una sala
  }

  // Metodo per rimuovere una sala
  removeSala(index: number) {
    this.cinemas.removeSala(this.form, index);  // Chiama il servizio per rimuovere una sala
  }


}
