import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-sala-proiezioni',
  templateUrl: './sala-proiezioni.component.html',
  styleUrls: ['./sala-proiezioni.component.scss']
})
export class SalaProiezioniComponent implements OnInit {

  groupedProiezioni = [];
  displayedColumns: string[] = ['data', 'film'];

  constructor(
    public dialogRef: MatDialogRef<SalaProiezioniComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.groupProiezioniByDate();
  }

  // Chiudi il dialog quando l'utente clicca sul pulsante di chiusura
  onClose(): void {
    this.dialogRef.close();
  }

  // Funzione per raggruppare le proiezioni per data
  groupProiezioniByDate() {
    const proiezioniByDate = {};

    this.data.proiezioni.forEach(proiezione => {
      const dateKey = new Date(proiezione.dataOra).toDateString(); // Usa la data come chiave
      if (!proiezioniByDate[dateKey]) {
        proiezioniByDate[dateKey] = [];
      }
      proiezioniByDate[dateKey].push(proiezione);
    });

    // Trasforma l'oggetto in un array per il rendering della tabella
    this.groupedProiezioni = Object.keys(proiezioniByDate).map(date => ({
      data: new Date(date),
      proiezioni: proiezioniByDate[date]
    }));
  }
}
