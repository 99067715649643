import { Injectable } from '@angular/core';
import { BaseService } from './_base.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../services/api/api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CinemasService {
  private _page = 1;  // pagina iniziale
  private _resourceName = 'cinema'; // nome della risorsa
  private _searchFields = 'title'; // in caso di ricerca, dove deve cercare
  
  constructor(private api:ApiService, private http: HttpClient,private baseProvider: BaseService, private fb: UntypedFormBuilder) {
    
  }

  getSchedulingFromApi(url_sync: string) {
    const encodedUrlSync = encodeURIComponent(url_sync);  // Codifica l'URL
    return this.http.get(`${environment._baseUrl}getScheduling?url_sync=${encodedUrlSync}`);  // Passa l'url_sync come query param
  }


  getForm(data?): UntypedFormGroup {
    data = data || {
      _id: '',
      id: '',
      title: '',
      indirizzo: '',
      comune: '',
      cap: '',
      provincia: '',
      regione: '',
      sito: '',
      mail: '',
      telefono: '',
      fax: '',
      lat: '',
      lng: '',
      films: [],
      imageGalleryData : [],
      saleCinemaData: [],
      circuito : '',
      associazione : '',
      iosapp : '',
      windowsapp : '',
      android_url : '',
      permalink : '',
      sync : false,
      url_sync : '',
      name_sync: ''
    };

    const form = this.fb.group({
      _id: [data['_id'], Validators.nullValidator],
      id: [data['id'], Validators.nullValidator],
      title: [data['title'], Validators.required],
      indirizzo: [data['indirizzo'], Validators.nullValidator],
      comune: [data['comune'], Validators.nullValidator],
      cap: [data['cap'], Validators.nullValidator],
      provincia: [data['provincia'], Validators.nullValidator],
      regione: [data['regione'], Validators.nullValidator],
      sito: [data['sito'], Validators.nullValidator],
      mail: [data['mail'], Validators.nullValidator],
      telefono: [data['telefono'], Validators.nullValidator],
      fax: [data['fax'], Validators.nullValidator],
      lat: [data['lat'], Validators.nullValidator],
      lng: [data['lng'], Validators.nullValidator],
      films: [data['films'], Validators.nullValidator],
      imageGalleryData: this.fb.array([]),
      saleCinemaData: this.fb.array([]),  // Aggiungi FormArray per le sale
      circuito :  [data['circuito'], Validators.nullValidator],
      associazione :  [data['associazione'], Validators.nullValidator],
      iosapp :  [data['iosapp'], Validators.nullValidator],
      windowsapp :  [data['windowsapp'], Validators.nullValidator],
      android_url :  [data['android_url'], Validators.nullValidator],
      permalink :  [data['permalink'], Validators.nullValidator],
      sync :  [data['sync'], Validators.nullValidator],
      url_sync :  [data['url_sync'], Validators.nullValidator],
      name_sync :  [data['name_sync'], Validators.nullValidator],
    });

    // inserisco le image gallery data
    const imageGalleryData = form.get('imageGalleryData') as UntypedFormArray;
    if ( data['imageGalleryData'] ){
      data['imageGalleryData'].map((igd) => {
        if (igd.content) {
          imageGalleryData.push(this.fb.group({
            title: igd.title,
            content: igd.content,
            orderitem: igd.orderitem || 0
          }));
        }
      });
    }


    // Popolazione dell'array delle sale cinema
    const saleCinemaData = form.get('saleCinemaData') as UntypedFormArray;
    
    if (data['saleCinemaData']) {

      const saleConProiezioni = data['saleConProiezioni'] || {};
      data['saleCinemaData'].map((sala) => {
        const screenId = sala.screenId.toString();

        // Recupera le proiezioni
        const proiezioni = saleConProiezioni[screenId] || [];

        // Debug: Verifica che le proiezioni siano corrette
        if (proiezioni.length === 0) {
          console.warn(`Nessuna proiezione trovata per screenId ${screenId}`);
        }

        // Aggiungi una nuova sala al form
        saleCinemaData.push(this.fb.group({
          nomeSala: [sala.nomeSala, Validators.required],
          numeroSala: [sala.numeroSala, Validators.required],
          capacita: [sala.capacita, Validators.required],
          dotazioni: [sala.dotazioni || []],  // Se ci sono dotazioni, aggiungile
          screenId: [sala.screenId || 0],  // screenId

          // Aggiunge le proiezioni per questa sala
          proiezioni: this.fb.array(proiezioni.map(proiezione => this.fb.group({
            titoloFilm: [proiezione.titoloFilm, Validators.required],
            dataOra: [proiezione.dataOra, Validators.required],
            durata: [proiezione.durata || null]  // Imposta durata se esistente
          })))
        }));
      });

    }



    return form;
  }

  


  addImageGalleryData(form) {
    const imageGalleryData = form.get('imageGalleryData') as UntypedFormArray;
    imageGalleryData.push(this.fb.group({
      title: '',
      content: '',
      orderitem: 0
    }));
  }

  // Metodo per aggiungere una nuova sala
addSala(form: UntypedFormGroup) {
  const saleCinemaData = form.get('saleCinemaData') as UntypedFormArray;
  saleCinemaData.push(this.fb.group({
    nomeSala: ['', Validators.required],
    numeroSala: ['', Validators.required],
    capacita: ['', Validators.required],
    dotazioni: [[]],  // Inizializza dotazioni come un array vuoto
    screenId: [[]]
  }));
}

// Metodo per rimuovere una sala
removeSala(form: UntypedFormGroup, index: number) {
  const saleCinemaData = form.get('saleCinemaData') as UntypedFormArray;
  if (saleCinemaData && saleCinemaData.length > index) {
    if (confirm('Si è sicuri di voler eliminare questa sala?')) {
      saleCinemaData.removeAt(index);
    }
  } else {
    console.error('Errore: L\'indice fornito non esiste nell\'array delle sale');
  }
}


    /**
   * Ritorna la lista o un singolo elemento
   * @param id - identifica se voglio che mi ritorni un determinato elemento
   * @param search - eventualmente una riceca
   */
  get(id?: string, search?: string) {
    id = id || null;
    search = search || '';

    if (id === null) {
      // richiedo la lista
      return this.baseProvider.list(this._resourceName, {
        page: this._page,
        searchFields: this._searchFields,
        search: search,
        sort: 'title'
      });
    } else {
      // richiedo un singolo oggetto
      return this.baseProvider.single(this._resourceName, id);
    }
  }

  /**
   * Ritorna regione del cienma
   */
  getRegione() {
    return this.baseProvider.utilsExternal(`regione`, null);
  }


  /**
   * Esegue il salvataggio o la modifica di una risorsa
   * @param form - la form
   */
  save(form: UntypedFormGroup) {
    if (form.value['_id'] !== null && form.value['_id'] !== '' && form.value['_id'].length > 0) {
      // Modifica esistente
      return this.baseProvider.update(this._resourceName, form.value);
    } else {
      // Nuovo inserimento
      const d = form.value;
      delete d._id;
      return this.baseProvider.insert(this._resourceName, d);
    }
  }


  /**
   * Esegue l'eliminazione
   * @param id
   */
  delete(id: string) {
    return this.baseProvider.delete(this._resourceName, id);
  }

  /**
   * Setta una nuova pagina
   * @param page
   */
  setPage(page: number) {
    this._page = page;
  }



}
